<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          {{ $t("DAILY_TASKS") }}
        </div>

        <div class="card-toolbar">
          <v-dialog
            :retain-focus="false"
            v-model="dialogAdd"
            max-width="1200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon2-plus"></i>
                {{ $t("ADD_NEW") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ $t("DAILY_TASK_CREATE") }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.name"
                        :label="$t('NAME')"
                        hide-details
                        single-line
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="5">
                      <v-text-field
                        v-model="form.hourlyRepeated"
                        :label="$t('TASK_HOURLY_REPEATED')"
                        hide-details
                        single-line
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                      <v-checkbox
                        :label="$t('TASK_IS_WAREHOUSE')"
                        v-model="form.is_warehouse"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row justify="space-between">
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('MONDAY')"
                        v-model="form.day1"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('TUESDAY')"
                        v-model="form.day2"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('WEDNESDAY')"
                        v-model="form.day3"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('THURSDAY')"
                        v-model="form.day4"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('FRIDAY')"
                        v-model="form.day5"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('SATURDAY')"
                        v-model="form.day6"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        :label="$t('SUNDAY')"
                        v-model="form.day7"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingDailyTasks"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="kitchen-equipments-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
            </SearchTools>
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.isWarehouse="{ item }">
            <v-chip :color="getColor(item.isWarehouse)" small>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.isWarehouse) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.isWarehouse) }}</v-chip
            >
          </template>

          <template v-slot:item.day1="{ item }">
            <v-simple-checkbox v-model="item.day1" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.day2="{ item }">
            <v-simple-checkbox v-model="item.day2" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.day3="{ item }">
            <v-simple-checkbox v-model="item.day3" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.day4="{ item }">
            <v-simple-checkbox v-model="item.day4" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.day5="{ item }">
            <v-simple-checkbox v-model="item.day5" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.day6="{ item }">
            <v-simple-checkbox v-model="item.day6" disabled></v-simple-checkbox>
          </template>
          <template v-slot:item.day7="{ item }">
            <v-simple-checkbox v-model="item.day7" disabled></v-simple-checkbox>
          </template>

          <template v-slot:item.hourlyRepeated="{ item }">
            {{
              item.hourlyRepeated
                ? item.hourlyRepeated != 1
                  ? "Всеки " + item.hourlyRepeated + " часа"
                  : "Всеки час"
                : "Веднъж на ден"
            }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip color="red" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="!item.isDefault"
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="red"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("DELETE") }}</span>
            </v-tooltip>
            <v-dialog
              v-model="dialogEdit"
              max-width="1200px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="!item.isDefault"
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="black"
                      @click="edit(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("EDIT") }}</span>
                </v-tooltip>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ $t("DAILY_TASK_EDIT") }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.name"
                          :label="$t('NAME')"
                          hide-details
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="5">
                        <v-text-field
                          v-model="editForm.hourlyRepeated"
                          :label="$t('TASK_HOURLY_REPEATED')"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="2">
                        <v-checkbox
                          :label="$t('TASK_IS_WAREHOUSE')"
                          v-model="editForm.isWarehouse"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row justify="space-between">
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('MONDAY')"
                          v-model="editForm.day1"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('TUESDAY')"
                          v-model="editForm.day2"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('WEDNESDAY')"
                          v-model="editForm.day3"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('THURSDAY')"
                          v-model="editForm.day4"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('FRIDAY')"
                          v-model="editForm.day5"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('SATURDAY')"
                          v-model="editForm.day6"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <v-checkbox
                          :label="$t('SUNDAY')"
                          v-model="editForm.day7"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="update()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              :retain-focus="false"
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this Fridge?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  FETCH_DAILY_TASKS,
  SAVE_DAILY_TASK,
  UPDATE_DAILY_TASK,
  DELETE_DAILY_TASK
} from "@/modules/daily-tasks/store/daily-tasks.module";
//Fetch Fridges
import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";

export default {
  name: "StoreDailyTasksSettings",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      firstLoader: true,
      search: "",
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      items: [],
      stores: [],
      totalItems: 100,
      searchQuery: "",
      form: {
        name: "",
        isWarehouse: false,
        day1: false,
        day2: false,
        day3: false,
        day4: false,
        day5: false,
        day6: false,
        day7: false,
        hourlyRepeated: null
      },
      clearFormEntry: {
        name: "",
        isWarehouse: false,
        day1: false,
        day2: false,
        day3: false,
        day4: false,
        day5: false,
        day6: false,
        day7: false,
        hourlyRepeated: null
      },
      editForm: {
        name: "",
        isWarehouse: false,
        day1: false,
        day2: false,
        day3: false,
        day4: false,
        day5: false,
        day6: false,
        day7: false,
        hourlyRepeated: null
      },
      deleteRecord: null,
      filters: {
        name: ""
      },
      options: {
        descending: false,
        page: 1,
        itemsPerPage: 10,
        totalItems: 100
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      headers: [
        { text: "Заглавие", value: "name", sortable: false },
        { text: "Обекти", value: "isWarehouse", sortable: false },
        { align: "center", text: "Пн", value: "day1", sortable: false },
        { align: "center", text: "Вт", value: "day2", sortable: false },
        { align: "center", text: "Ср", value: "day3", sortable: false },
        { align: "center", text: "Чт", value: "day4", sortable: false },
        { align: "center", text: "Пт", value: "day5", sortable: false },
        { align: "center", text: "Съб", value: "day6", sortable: false },
        { align: "center", text: "Нед", value: "day7", sortable: false },
        { text: "Повтаряне", value: "hourlyRepeated", sortable: false },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  components: {
    SearchTools
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daily Tasks Settings", route: { name: "list-daily-tasks" } },
      { title: "Daily Tasks List" }
    ]);
  },
  computed: {
    ...mapGetters(["getDailyTasks", "isLoadingDailyTasks"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialogAdd(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialogAdd = false;
      vm.dialogEdit = false;
    },
    closeDelete() {
      let vm = this;
      vm.dialogDelete = false;
    },
    deleteItem(item) {
      this.deleteRecord = Object.assign({}, item);
      this.dialogDelete = true;
    },
    edit(item) {
      this.editForm = Object.assign({}, item);
      this.dialogEdit = true;
    },
    save() {
      let vm = this;
      this.$store
        .dispatch(SAVE_DAILY_TASK, vm.form)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.form = vm.clearFormEntry;
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    update() {
      let vm = this;
      let id = vm.editForm.id;

      this.$store
        .dispatch(UPDATE_DAILY_TASK, { id: id, payload: this.editForm })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    deleteItemConfirm() {
      let vm = this;
      this.$store
        .dispatch(DELETE_DAILY_TASK, vm.deleteRecord.id)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            this.closeDelete();
            this.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
      this.doSearch();
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;

      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_DAILY_TASKS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "primary";
      else return "";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Ресторанти";
      else return "Складове";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-silverware-variant";
      else return "mdi-package-down";
    }
  }
};
</script>
